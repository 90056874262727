<template>
  <div>
    <a-modal
      :visible="visible"
      title="新增BOM"
      :width="1000"
      :confirmLoading="confirmLoading"
      :destroyOnClose="true"
      :maskClosable="false"
      @cancel="handleCancel"
      @ok="handleConfirm"
    >
      <a-form :form="dataForm" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
        <a-row>
          <a-col :span="12">
            <a-form-item label="父物料">
              <GoodsSelect
                v-decorator="[
                  'goods',
                  {
                    rules: [{ required: true, message: '请选择物料' }],
                  },
                ]"
                @select="(item) => (productItem = item)"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="父物料编号">
              <a-input :value="productItem.number" :disabled="true" />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="父物料规格">
              <a-input :value="productItem.spec" :disabled="true" />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="父物料尺寸">
              <a-input :value="productItem.drawing_number" :disabled="true" />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="基础BOM">
              <a-switch
                v-decorator="[
                  'is_basic',
                  {
                    valuePropName: 'checked',
                    initialValue: false,
                  },
                ]"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="备注">
              <a-input v-decorator="['remark', { rules: [{ max: 256, message: '超出最大长度(256)' }] }]" :allowClear="true" />
            </a-form-item>
          </a-col>

          <a-col :span="24">
            <a-form-item label="BOM清单" :label-col="{ span: 3 }" :wrapper-col="{ span: 21 }">
              <BOMComponentTable v-decorator="['bom_component_items', { initialValue: [] }]" />
            </a-form-item>
          </a-col>
          <a-col :span="24">
            <a-form-item label="附件" :label-col="{ span: 3 }" :wrapper-col="{ span: 21 }">
              <BOMFileUpload v-decorator="['bom_files', { initialValue: [] }]" />
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { bomDetailCreate } from "@/api/goods";

export default {
  components: {
    GoodsSelect: () => import("@/components/GoodsSelect"),
    BOMComponentTable: () => import("@/components/BOMComponentTable"),
    BOMFileUpload: () => import("@/components/BOMFileUpload"),
  },
  props: ["visible"],
  model: { prop: "visible", event: "cancel" },
  data() {
    return {
      confirmLoading: false,
      dataForm: null,
      productItem: {},
    };
  },
  methods: {
    handleConfirm() {
      this.dataForm.validateFields((error, values) => {
        if (error === null) {
          // 验证 BOM原料
          if (values.bom_component_items.length === 0) {
            this.$message.warning("BOM清单未添加原料");
            return;
          }

          for (const bomComponentItem of values.bom_component_items) {
            if (!bomComponentItem.goods) {
              this.$message.warning("BOM清单未选择原料");
              return;
            }
          }

          this.confirmLoading = true;
          bomDetailCreate(values)
            .then((data) => {
              this.$emit("create", data);
              this.$message.success("新增成功");
              this.handleCancel();
            })
            .finally(() => {
              this.confirmLoading = false;
            });
        }
      });
    },
    handleCancel() {
      this.productItem = {};
      this.$emit("cancel", false);
    },
  },
  mounted() {
    this.dataForm = this.$form.createForm(this);
  },
};
</script>

<style scoped></style>
